import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Img from './Img';
import { parseUrl } from './../../../helpers/urlParser';
import '../Styles/share.style.css';
import { getImageUrl } from '../../../helpers/imageUrl';
import { lang } from '../../../helpers/language';

export default class Share extends Component {
  state = {};

  componentDidMount() {
    const { place } = this.props;
    const script = document.createElement('script');

    script.src = lang.shareThis[place];
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    // let { spec, productId, fontDetail, location, orderStatus, place } = this.props;
    let { spec, productId, fontDetail, location, orderStatus } = this.props;

    const { search } = location;
    let fromUrl;
    if (search) {
      fromUrl = parseUrl(search);
    }

    if (fromUrl && fromUrl.status) {
      spec = fromUrl.spec;
      productId = fromUrl.activeProduct.id;
      fontDetail = fromUrl.fontDetail;
    }

    return (
      <section className="container share">
        <MetaTags>
          <meta
            name="description"
            content="With 7 colours in endless combinations, design a Springfree that reflects your style."
          />
          <meta
            property="og:title"
            content="Design your custom Springfree Trampoline! - Springfree"
          />
          <meta
            property="og:image"
            content={getImageUrl({
              ...spec,
              productId,
              fontDetail,
            })}
          />
        </MetaTags>
        <header className="product__header">
          <a href={lang.url[location]}>
            <img src="/img/springfree.svg" alt="Springfree logo" className="logo" />
          </a>
          <a href="/" className="btn btn--border btn--small">
            Restart
          </a>
        </header>
        <div className="share__image--wrapper">
          <h1 className="share__heading">Fantastic design choice!</h1>
          {orderStatus && (
            <div>
              <p>
                One of our sales representatives will be in touch shortly to help finalize your
                order.
              </p>
              <p>
                To speak to someone right away, please contact our customer care team at{' '}
                <strong>
                  <a href="tel:18775867723">1.877.586.7723</a>
                </strong>
              </p>
            </div>
          )}

          {!orderStatus && (
            <div>
              <p>
                We’ve sent your design to your inbox. But sometimes our emails get stuck in spam
                filters, remember to check there for our email.
              </p>

              <p>
                If you’d like more help with your design, please contact our customer care team on {' '}
                <strong>
                  <a href="tel:18775867723">1 (877) 586-7723</a>
                </strong> or at {' '}
                <strong>
                  <a href="mailto:customercare@springfree.com">customercare@springfree.com</a>
                </strong>
              </p>
            </div>
          )}

          <div className="share__image">
            <Img
              imgUrl={getImageUrl({
                ...spec,
                productId,
                fontDetail,
              })}
            />
          </div>

          <div className="share__actions">
            <div className="sharethis-inline-share-buttons"></div>
          </div>
        </div>
      </section>
    );
  }
}
