export const lang = {
  zip: {
    us: 'Zip Code',
    ca: 'Postal Code',
  },
  color: {
    us: 'color',
    ca: 'colour',
  },
  url: {
    us: 'https://www.springfreetrampoline.com/',
    ca: 'https://www.springfreetrampoline.ca/',
  },
  api_dev: {
    us: 'https://3dapi-stage.springfreetrampoline.com/v1/item',
    ca: 'https://3dapi-stage.springfreetrampoline.ca/v1/item',
  },
  api_prod: {
    us: 'https://3dapi-prod.springfreetrampoline.com/v1/item',
    ca: 'https://3dapi-prod.springfreetrampoline.ca/v1/item',
  },
  state: {
    us: 'State',
    ca: 'Province',
  },
  tagManager: {
    us: 'GTM-PVT26RC',
    ca: 'GTM-P67WZKK',
  },
  analyticsTracker: {
    us: 'UA-2765467-33',
    ca: 'UA-2765467-34',
  },
  shareThis: {
    us:
      'https://platform-api.sharethis.com/js/sharethis.js#property=5aa48e25cd1736001392fbb7&product=inline-share-buttons',
    ca:
      'https://platform-api.sharethis.com/js/sharethis.js#property=5aba6734003b52001341aee9&product=inline-share-buttons',
  },
};
