import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './scrollView.style.css';

const ScrollView = ({ settings, children, activeIndex, heading }) => {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const style = {
    // fontSize: '11px',
    fontSize: '13px',
    paddingLeft: '13px',
    fontWeight: 600,
    //color: '#0a0a0a', //black
    //color: "#6fb243",
    color:"#f00", // red
  };
  const danger_text = {
    fontSize: '11px',
    paddingLeft: '13px',
    fontWeight: 600,
    color: '#0a0a0a',
    //color: "#fd0021",
  };

  return (
    <div>
      <div className='scroll-view__header'>
        <h1 className='scroll-view__heading'>{heading}</h1>

        <div className='scroll-view__controls'>
          <span
            className='scroll-view__controls--button'
            style={{ opacity: activeIndex === 0 ? 0.4 : 1 }}
            onClick={previous}
          >
            <img src='/img/nav-prev.svg' alt='Previous' />
          </span>
          <span
            className='scroll-view__controls--button'
            style={{ opacity: activeIndex === children.length - 1 ? 0.4 : 1 }}
            onClick={next}
          >
            <img src='/img/nav-next.svg' alt='Next' />
          </span>
        </div>
      </div>

      {heading === 'Give your trampoline a special name!' && (
        <p style={{ 'padding-left': '15px' }}></p>
      )}
      {heading ==
        'This customizer tool is currently under maintenance and some features may not work. Please call our customer line @ 1-877-JUMPSAFE or check back later. Sorry for the inconvenience.' && (
        <div style={style}>
          {/* <p>$199 custom fee applied at checkout.</p> */}
        </div>
      )}
      {heading == 'Choose your trampoline model' && (
        <div>
          {/* <span style={danger_text}>Save up to $419 off custom trampolines. </span> */}
          <span style={style}>$199 Custom Fee Applies At Checkout.</span>
        </div>
      )}
      <Slider {...settings} ref={sliderRef}>
        {children}
      </Slider>

      {/*<div class="color-notified">Subject to eligibility. Payment options through Affirm are provided by these lending partners: <a target="_blank" href="https://www.affirm.com/lenders">affirm.com/lenders</a></div>*/}
    </div>
  );
};

export default ScrollView;
