export const accessoryData = [
  {
    name: "FlexrHoop",
    id: "flexHoop",
    image: "/img/accessories/hoop.png",
    us: {
      O77: "$249",
      O92: "$249",
      S113: "$249",
      S155: "$249",
    },
    ca: {
      O77: "$249",
      O92: "$249",
      S113: "$249",
      S155: "$249",
    },
    status: {
      code: 1,
      message: "",
    },
  },
  {
    name: "FlexrStep",
    id: "flexrStep",
    image: "/img/accessories/step.png",
    us: {
      O77: "$149",
      O92: "$149",
      S113: "$149",
      S155: "$149",
    },
    ca: {
      O77: "$149",
      O92: "$149",
      S113: "$149",
      S155: "$149",
    },
    status: {
      code: 2,
      message:
        "No shipment date currently available. Call 1 (877) 586-7723 for more info.",
    },
  },
  {
    name: "Sunshade",
    id: "sunshade",
    image: "/img/accessories/sunshade.png",
    us: {
      O77: "$199",
      O92: "$219",
      S113: "$239",
      S155: "$249",
    },
    ca: {
      O77: "$219",
      O92: "$239",
      S113: "$279",
      S155: "$299",
    },
    status: {
      code: 2,
      message:
        "No shipment date currently available. Call 1 (877) 586-7723 for more info.",
    },
  },
  {
    name: "Cover",
    id: "cover",
    image: "/img/accessories/cover.png",
    us: {
      O77: "$249",
      O92: "$269",
      S113: "$279",
      S155: "$299",
    },
    ca: {
      O77: "$269",
      O92: "$289",
      S113: "$319",
      S155: "$349",
    },
    status: {
      code: 1,
      message: "",
    },
  },
  {
    name: "Ground Anchor",
    id: "groundAnchor",
    image: "/img/accessories/anchor.png",
    us: {
      O77: "$99",
      O92: "$99",
      S113: "$99",
      S155: "$139",
    },
    ca: {
      O77: "$99",
      O92: "$99",
      S113: "$99",
      S155: "$139",
    },
    status: {
      code: 1,
      message: "",
    },
  },
  {
    name: "Shifting Wheels",
    id: "shiftingWheels",
    image: "/img/accessories/wheels.png",
    us: {
      O77: "$99",
      O92: "$99",
      S113: "$99",
      S155: "$99",
    },
    ca: {
      O77: "$99",
      O92: "$99",
      S113: "$99",
      S155: "$99",
    },
    status: {
      code: 1,
      message: "",
    },
  },
  // {
  //   name: 'tgoma',
  //   id: 'tgoma',
  //   image: '/img/accessories/tgoma.png',
  //   us: {
  //     O77: '$349',
  //     O92: '$349',
  //     S113: '$349',
  //     S155: '$349',
  //   },
  //   ca: {
  //     O77: '$399',
  //     O92: '$399',
  //     S113: '$399',
  //     S155: '$399',
  //   },
  //   status: {
  //     code: 1,
  //     message: 'Ships within 21 business days',
  //   },
  // },
];
