import React from 'react';
import './model.style.css';
import ProductMessage from './ProductMessage';
import ProductStatus from './ProductStatus';

const ModelCards = ({
  type,
  selectIndex,
  cardIndex,
  activeIndex,
  name,
  dimension,
  price,
  image,
  data,
  location,
  status,
}) => {
  let isActive = activeIndex === cardIndex ? 'model-card active' : 'model-card';

  if (status?.code === 2) {
    isActive += ' inactive';
  }

  const productDetail = {
    name,
    dimension,
    price,
  };

  const handleClick = () => {
    if (data) {
      selectIndex(cardIndex, productDetail);
    }
  };

  if (type === 'accessories') {
    return (
      <div
        className={isActive}
        onClick={() => selectIndex(cardIndex, productDetail)}
      >
        <div className='model-card__wrapper'>
          <ProductStatus status={status} />
          <h2 className='model-card__heading'>{name}</h2>
          <p className='model-card__price'>{price}</p>
          <p className='model-card__image'>
            <img src={image} alt='' />
          </p>
          <ProductMessage status={status} />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ opacity: data ? 1 : 0.6 }}
      className={isActive}
      onClick={handleClick}
    >
      <div className='model-card__wrapper'>
        <ProductStatus status={status} />
        <h2 className='model-card__heading'>{name}</h2>
        <p className='model-card__price'>
          {price ? '$' + price[location] : 'Coming soon!'}
        </p>
        <p className='model-card__dimension'>{dimension} ft</p>
        <p className='model-card__image'>
          <img src={image} alt='' />
        </p>
        <ProductMessage status={status} />
      </div>
    </div>
  );
};

export default ModelCards;
